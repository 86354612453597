import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LinkExternal from "../components/link_external"
import BibleImage from "../images/bible.jpg"
import Routes from "../routes"
import { useImpression } from "../analytics/utils"

const Bible = ({ location }) => {
  useImpression("Bible")
  return (
    <Layout location={location}>
      <Seo
        title="The Bible: What it says and means"
        description="The Bible is one of the world's most popular books. What is it, and what does it really say about some of our most highly contested questions?"
      />
      <h1>The Bible</h1>
      <img
        src={BibleImage}
        alt="Bible open to Psalms"
        width="400"
        style={{ float: "right", padding: 20 }}
      />
      <h2>What does the Bible say about...?</h2>
      <h3>What does the Bible say about abortion?</h3>
      <p>
        Well, the word "abortion" does not appear in any translation of the
        bible.{" "}
        <LinkExternal to="https://ffrf.org/component/k2/item/18514-what-does-the-bible-say-about-abortion">
          Check this out
        </LinkExternal>{" "}
        for more information.
      </p>
      <h3>What does the Bible say about love?</h3>
      <p>
        “God created man in His own image...male and female He created them.
        Then God blessed them, and God said to them, ‘Be fruitful and multiply;
        fill the earth’ ” (Genesis 1:27, 28, NKJV).
      </p>
      <p>
        This can be seen as the first marriage, supposedly occurring on the
        sixth day of God's creation of the Earth. Reading on we see, “He brought
        her to the man. And Adam said, ‘This is now bone of my bones and flesh
        of my flesh.’ . . . Therefore a man shall leave his father and mother
        and be joined to his wife, and they shall become one flesh” (Genesis
        2:22-24).
      </p>
      <h3>What does the Bible say about divorce?</h3>
      <p>
        Within Leviticus and Deuteronomy, there are statements of law regarding
        divorce. Some keywords being: <i>garash</i>, a Hebrew word meaning “To
        drive out from a possession. To cast out a woman. Put away from her
        husband,”; the Hebrew word <i>kriythuwth </i>, “To cut, destroy or
        consume. To sever something from something else by cutting with a blade.
        In the case of a cut off person... not killed but driven out of the
        family and removed from the blessing of the covenant.”
      </p>
      <p>
        During the times the Bible was assembled, getting a divorce required a
        written bill. According to the Old Testament, a man can divorce his wife
        of she failed to please him, or if he found uncleanliness in her. If so,
        she was cast away.
      </p>
      <p>
        “When a man takes a wife and marries her, and it happens that she finds
        no favor in his eyes because he has found some indecency in her, and he
        writes her a certificate of divorce and puts in her hand and sends her
        out from his house, and she leaves his house and goes and becomes
        another man’s wife, and if the latter husband turns against her and
        writes her a certificate of divorce and puts it in her hand and sends
        her out of his house, or if the latter husband dies who took her to be
        his wife, then her former husband who sent her away is not allowed to
        take her again to be his wife, since she has been defiled; for that is
        an abomination before the Lord, and you shall not bring sin on the land
        which the Lord your God gives you as an inheritance” (Deuteronomy 24:1-4
        NASB).
      </p>
      <h3>What does the Bible say about slavery?</h3>
      <p>
        When you start looking at the Bible for answers regarding slavery, you
        quickly learn some alarming information things. For example:
      </p>

      <p>
        The Bible translations do refer to slaves. In Exodus 21 we find the
        passage, "When you buy a Hebrew slave, he shall serve six years, and in
        the seventh, he shall go out free, for nothing." This clearly implies
        the acceptability of purchasing and releasing another human being based
        on their religious identity. The next paragraph goes on to clarify how a
        man can sell his daughter as a slave. Exodus is particularly nasty when
        discussing slavery, while Deuteronomy and Leviticus are a bit less
        brutal. So according to the Old Testament, slavery is acceptable.
      </p>
      <p>
        The New Testament does not insist or command the releasing of slaves
        either. In fact, there were times when slaves were beaten and killed in
        the New Testament.
      </p>
      <p>
        If these books really are the word of God, why would they contain so
        many negative things and not address them? Why wouldn't God have created
        this as one of the commandments? Either it was not important enough, or
        it's not unacceptable. Both of which are nonsense iimport Bible from
        './bible'; n the modern world.
      </p>

      <h2>Thomas Paine on the Bible</h2>
      <p>
        In Thomas Paine's The Age of Reason, he writes about the a scathing
        review of the Bible.
      </p>
      <p>
        “It is because ye are sunk in the cruelty of superstition, or feel no
        interest in the honour of your Creator, that ye listen to the horrid
        tales of the Bible, or hear them with callous indifference. The evidence
        I have produced, and shall still produce in the course of this work, to
        prove that the Bible is without authority, will, whilst it wounds the
        stubbornness of a priest, relieve and tranquilize the minds of millions:
        it will free them from all those hard thoughts of the Almighty which
        priest-craft and the Bible had infused into their minds, and which stood
        in everlasting opposition to all their ideas of his moral justice and
        benevolence.”
      </p>
      <hr />
      <p>
        Check out our other page to learn more about the{" "}
        <Link to={Routes.TEN_COMMANDMENTS}>Ten Commandments</Link>.
      </p>
    </Layout>
  )
}

export default Bible
